<template>
  <div id="sireac">
    <div class="title_des">
      <p>SiREAC无线智能门禁系统</p>
      <p>
        SiREAC无线智能门禁系统采用LoRa物联网无线通信技术突破性地取消了传统门禁控制器、无需布线前端验证读卡器及生物识别验证设备端可直接与LoRa集中器基站交互大幅降低安装和维护成本，将建筑内门禁系统升级变得非常快捷甚至无感。</p>
    </div>
    <div class="sireac_sys">
      <p>系统架构</p>
      <img
        src="../assets/产品服务_硬件产品/sireac00.8b0ac3e.png"
        alt="系统架构" width="100%"
      />
    </div>
    <div class="sireac_intro">
      <p>产品介绍</p>
      <div class="sireac_intro_down">
        <div class="sireac_intro_down_Fir">
          <div class="sireac_intro_down_left">
            <img
              src="../assets/产品服务_硬件产品/sireac01.8b0ac3e.png"
              alt=""
            />
          </div>
          <div class="sireac_intro_down_right">
            <p>一个LoRa集中器=100+个门禁控制器</p>
            <p>独立操作系统、高安全性</p>
            <p>多通道LoRa组网、高效并发处理</p>
            <p>多种联网方式 4G/WIFI/网线、多场景灵活运用</p>
            <p class="sireac_lastDes">
              超广覆盖：点对点可以到达15层楼通讯距离、无盲点实现3—4层全面覆盖
            </p>
          </div>
        </div>
        <div class="sireac_intro_down_Sec">
          <div class="sireac_intro_down_right">
            <p>内嵌智能无线门禁管理系统，1U机架式标准外型、便于管理</p>
            <p>开放平台、高效集成</p>
            <p>工业级服务器、稳定性高</p>
            <p>多重加密防护、安全性高</p>
          </div>
          <div class="sireac_intro_down_left">
            <img
              src="../assets/产品服务_硬件产品/sireac02.b84f049.png"
              alt="内嵌智能无线管理系统"
            />
          </div>
        </div>
        <div class="sireac_intro_down_thi">
          <div class="sireac_intro_down_left">
            <img
              src="../assets/产品服务_硬件产品/sireac03.8b9c043.png"
              alt=""
            />
          </div>
          <div class="sireac_intro_down_right">
            <p>无线门禁读卡器适用于企业办公楼、闸机、隔断式开放办公区域</p>
            <p>易改造，就近取电，不需要门禁控制器、通信线等</p>
            <p>支持读IC卡、CPU卡、等多种卡片</p>
            <p class="sireac_lastDes">
              典型案例：中国农业银行门禁改造项目
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@import url("../css/SiREAC.css");
</style>
<template>
  <div id="core">
    <div class="core_warp">
      <div class="core_title">
        <h1>核心技术</h1>
        <p class="descri">CORE TECHNOLOGY</p>
      </div>
      <div class="core_down">
        <div class="core_downItem">
          
          <div class="core_item">
            <div class="core_itemLeft">
              <p>CIM轻量化技术</p>
              <img
                src="../assets/核心技术/BIM轻量化技术.png"
                alt="BIM轻量化技术"
              />
            </div>
            <div class="core_itemTxt">
              <p>
                CIM作为先进的设计、施工工具广泛应用于各类标志性建筑当中，但其巨大的繁琐数据承载能力也成为后期应用的技术瓶颈。公司自己研发的CIM轻量化技术可以根据CIM使用情况对CIM进行轻量化处理，甚至可以在网页上应用。
              </p>
            </div>
          </div>
          <div class="core_item">
            <div class="core_itemLeft">
              <p>CIM人工智能与大数据技术</p>
              <img
                src="../assets/核心技术/大数据.png"
                alt="BIM人工智能与大数据技术"
              />
            </div>
            <div class="core_itemTxt">
              <p>
                语音识别能够解放我们的眼睛和双手，极大提高我们的管理效率。公司将语音识别与CIM之间共性技术进行打通，研发了基于BIM三维空间的智慧语音控制及播报产品，实现了在该人工智能领域的突破。大数据分析与BIM结合实现数字孪生有机融合，提高数据可视化、直观化形式。
              </p>
            </div>
          </div>
          <div class="core_item">
            <div class="core_itemLeft">
              <p>动态数字孪生</p>
              <img
                src="../assets/核心技术/动态技术孪生.png"
                alt="动态数字孪生"
              />
            </div>
            <div class="core_itemTxt">
              <p>
                CIM轻量化技术；分布式可视一体化胶水系统；移动端BIM智慧化运维技术；CIM+AR+VR综合解决方案；CIM人工智能技术
              </p>
            </div>
          </div>
          <div class="core_item">
            <div class="core_itemLeft">
              <p>CIM+AR+VR</p>
              <img
                src="../assets/核心技术/BIM+GIS+AR+VR.png"
                alt="BIM+GIS+AR+VR"
              />
            </div>
            <div class="core_itemTxt">
              <p>
                CIM移动端整体应用：通过虚拟现实技术的理念结合大数据、物联网、云平台和人工智能，打造智慧管理新体验。
              </p>
            </div>
          </div>

          <div class="core_item">
            <div class="core_itemLeft">
              <p>物联网</p>
              <img src="../assets/核心技术/物联网.png" alt="物联网" />
            </div>
            <div class="core_itemTxt">
              <p>
                公司在物联网的基础之上，运用自己研发的CIM物联网技术将感知层、网络层和应用层协调同成为统一体系，使得数据互联的同时，物与物相通。
              </p>
            </div>
          </div>
          <div class="core_item">
            <div class="core_itemLeft">
              <p>云技术</p>
              <img src="../assets/核心技术/云技术.png" alt="云技术" />
            </div>
            <div class="core_itemTxt">
              <p>
                公司在云技术的基础之上，运用公司自己研发的云计算法，成功实现了数据的分布式计算、并行计算、效用计算、利用网络存储、虚拟化、负载均衡、热备份冗余等计算机和网络技术实现了CIM数据的云端化。
              </p>
            </div>
          </div>

          <div class="core_item">
            <div class="core_itemLeft">
              <p>大数据</p>
              <img src="../assets/核心技术/大数据.png" alt="大数据" />
            </div>
            <div class="core_itemTxt">
              <p>
                公司在云技术的基础之上，对业主关心的数据进行挖掘分析，并进行阈值预测，将分析结果和预测结果与CIM进行联动，实现智慧化数据管理。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("../css/technology.css");
</style>
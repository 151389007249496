<template>
  <div id="projectCase">
    <!-- 智慧运维项目开始 -->
    <div class="projectCase_wrap">
      <div class="projectCase_title">
        <h1>产业物联网项目</h1>
        <p class="descri">SMART OPERATION AND MAINTENANCE</p>
      </div>
      <div class="projectCase_downWrap">
        <div class="case_item">
          <img src="../assets/项目案例/31、北京奥运博物馆案例.png" />
          <h4>中国国家体育场（鸟巢）</h4>
          <span>奥运场馆</span>
          <div class="case_item_down">
            国家体育场（鸟巢）位于北京奥林匹克公园中心区南部，为2008年北京奥运会的主体育场，占地20.4公顷，建筑面积25.8万平方米，可容纳观众9.1万人。
            举行了奥运会、残奥会开闭幕式、田径比赛及足球比赛决赛。该系统包含照明管理、声讯管理、动力机房管理等模块，将CIM平台应用到大型体育场馆运维当中，提升了管理效率，降低管理人员的劳动强度。
          </div>
        </div>
        <div class="case_item">
          <img src="../assets/项目案例/17、国投集团.png" />
          <h4>国家开发投资集团有限公司</h4>
          <span>政府类</span>
          <div class="case_item_down">
            国家开发投资集团有限公司（简称“国投”）成立于1995年5月5日，是中央直接管理的国有重要骨干企业，是中央企业中唯一的投资控股公司，是首批国有资本投资公司改革试点单位。系统包括资产可视化管理，机房可视化管理、RFID盘点实时联动等模块。
          </div>
        </div>

        <div class="case_item">
          <img src="../assets/项目案例/9、中国科学建筑研究院.jpg" />
          <h4>中国建筑科学研究院环能楼</h4>
          <span>研究院</span>
          <div class="case_item_down">
            中国建筑科学研究院建筑环境与节能研究院成立于2006年8月，由原中国建筑科学研究院空气调节研究所和建筑物理研究所整合而成，隶属于中国建筑科学研究院。是国内建筑环境与建筑节能领域最大的国家级科研机构，致力于中国建筑领域的科研、标准规范、设计、咨询、检测等诸多领域，依靠自身固有的人才优势和行业地位，为社会提供安全、健康、环保、舒适的人居环境。系统将环能院打造成基于数字孪生的智慧化综合节能楼宇，在数字孪生楼宇的同时将AR技术融入到楼宇管理当中，实现了节能设备隐蔽管线的可视化精准管理，提高了管理人员的工作效率，为应急指挥提供了科学的决策依据。
          </div>
        </div>
        <div class="case_item">
          <img src="../assets/项目案例/32、中关村新兴产业技术.png" />
          <h4>中关村新兴产业技术研究院</h4>
          <span>中关村</span>
          <div class="case_item_down">
            研究院依托中关村国家自主创新示范区的科技智力优势，聚焦新能源、新材料和智能制造技术，打造前沿科技成果转化和产业化服务创新平台，为优化北京“高精尖”产业布局，助力非首都功能疏解提供支撑，成为首都南部科技成果转化带建设的新引擎。
          </div>
        </div>
        <div class="case_item">
          <img src="../assets/项目案例/8、上海海洋极地公园.png" />
          <h4>上海海洋极地公园</h4>
          <span>旅游类</span>
          <div class="case_item_down">
            上海海昌极地海洋公园，位于浦东新区南汇新城滴水湖畔，总占地面积约29.7公顷，总规划建筑面积约为19万平方米，包含12个展示场馆、4个大型海洋动物互动体验项目、3个特效影院以及15项大中型游乐设备。系统包含基于BIM+GIS+BMS智慧园区多个智能化监测与控制模块，实现上海海昌极地海洋公园的智慧化管理。
          </div>
        </div>
        <div class="case_item">
          <img src="../assets/项目案例/20、锡林郭勒超高压供电局.png" />
          <h4>500kV超高压变电站基于数字孪生的智能电网</h4>
          <span>500kV超高压变电站</span>
          <div class="case_item_down">
            该系统包括500KV变电站一次设备、二次设备、一/二次线缆管理等九大管理模块。
          </div>
        </div>
        <div class="case_item">
          <img src="../assets/项目案例/33、启迪之星雄安未来城市创新中心.png" />
          <h4>河北雄安启迪之星</h4>
          <span>孵化器</span>
          <div class="case_item_down">
            启迪之星雄安未来城市创新中心是启迪之星全球创新网络的中心节点，致力于打造成为雄安新区智慧城市和智能小镇各项应用的展示平台。启迪之星雄安位于安新县旅游路白洋淀之畔，占地面积百亩，可用办公面积1.4万余平米，是智慧城市/智慧园区技术落地示范展示的天然场景和平台。
          </div>
        </div>

        <div class="case_item">
          <img src="../assets/项目案例/15、海底捞大拇指店.png" />
          <h4>海底捞上海大拇指店</h4>
          <span>餐饮类</span>
          <div class="case_item_down">
            海底捞成立于1994年，是一家以经营川味火锅为主、融汇各地火锅特色为一体的大型跨省直营餐饮品牌火锅店，全称是四川海底捞餐饮股份有限公司，创始人张勇，海底捞上海大拇指店智慧管理系统包含触屏控制、虚拟选座、空调控制等智慧管理模块。
          </div>
        </div>
        <div class="case_item">
          <img src="../assets/项目案例/14、大同市国际能源革命科技创新园A.png" />
          <h4>大同市国际能源革命科技创新园</h4>
          <span>能源类</span>
          <div class="case_item_down">
            大同市能源革命科技创新产业园，是大同市争当能源革命尖兵、培育打造新能源主导产业的战略高地。园区位于大同大学以东，云山街以南，太和路以西，占地面积约1000亩。CIM系统包含智慧园区、海绵城市、智慧绿色、被动式建筑、装配式建筑等管理模块，将能源革命展示馆打造成园区智慧标杆。
          </div>
        </div>
        <!-- <div class="case_item">
          <img src="../assets/项目案例/2、合肥鑫晟光电科技有限公司.png" />
          <h4>
            合肥鑫晟光电科技有限公司
          </h4>
          <span>工业类</span>
        </div> -->
      </div>
      <div class="case_more" @click="caseMore">
        <span ref="showtxt">查看更多</span>
      </div>
    </div>
    <!-- 智慧运维项目结束 -->

    <!-- 平台名称开始 -->
    <div class="Platfor_wrap" ref="show">
      <div class="platfor_title">
        <span>项目名称</span>
      </div>
      <div class="platfor_content">
        <p>
          合肥鑫晟光电科技有限公司动能监控系统项目<br />
          工业化建筑综合评价监管软件开发<br />
          国投集团资产管理平台建设项目<br />
          固定资产管理系统项目<br />
          重庆渝高集团 北恒办公楼改造项目<br />
          北京奥运博物馆智能化二期工程项目<br />
        </p>
      </div>
    </div>
    <!-- 平台名称结束 -->

    <!-- 咨询及科研项目应用开始 -->
    <div class="scientific_caseWrap">
      <div class="scientific_title">
        <h1>咨询及科研项目应用</h1>
        <p class="descri">CONSULTATION AND RESEARCH</p>
      </div>
      <div class="scientific_center">
        <span>BIM及相关解决方案</span>
      </div>
      <div class="scientific_content">
        <p>
          中国尊(北京第一高楼)BIM智慧运维平台<br />
          火神山医院BIM安防与空气质量管理<br />
          上海博彦大楼BIM+GIS+BMS平台软件服务供应<br />
          内蒙古网讯基于BIM技术为核心的数字化平台<br />
          国投大厦BIM智慧资产管理云平台<br />
          南阳BIM运维<br />
          基于BIM数据中心可视化管理平台<br />
          青岛万象城购物中心基于BIM智慧管理云平台<br />
          基于BIM智慧建筑及区域管理平台<br />
          中核宾馆BIM系统<br />
          BIM设计全过程协同管理软件云平台<br />
          宏福苑供热站BIM设备设施管理平台<br />
          基于BIM的轨道交通管理回龙观-霍营站软件系统<br />
        </p>
      </div>
    </div>
    <!-- 咨询及科研项目应用结束 -->


  </div>
</template>

<script>
export default {
  data() {
    return {
      showOff: true,
    };
  },
  methods: {
    caseMore() {
      let Platfor_wrap = this.$refs.show;
      let showtxt = this.$refs.showtxt;
      if (this.showOff) {
        Platfor_wrap.style.height = "300px";
        Platfor_wrap.style.opacity = "1";
        showtxt.innerHTML = "收起";
      } else {
        Platfor_wrap.style.height = "0px";
        Platfor_wrap.style.opacity = "0";
        showtxt.innerHTML = "查看更多";
      }
      this.showOff = !this.showOff;
    },
  },
};
</script>

<style scoped>
@import url("../css/ProjectCase.css");


</style>
<template>
  <div class="projectServer">
    <!-- BIMview软件系统开始 -->
    <div class="core_title">
      <h1>BimView软件系统</h1>
      <p class="descri">BIMVIEW SOFTWARE SYSTEM</p>
    </div>
    <div id="bimView">
      <table class="bimView_tab_first">
        <tr id="bimView_tab_top">
          <td>序号</td>
          <td>说明</td>
          <td>型号</td>
          <td>描述</td>
          <td>货期</td>
        </tr>

        <tr class="bimView_tab_title">
          <td colspan="5">BIM建筑能源管理大数据平台</td>
        </tr>
        <tr>
          <td>1</td>
          <td>BIMview大数据集成软件4.0</td>
          <td>BIM-Sight-5</td>
          <td>
            BIMview
            建筑物能源管理集成软件平台；5万平方米以内建筑物，场景空间视觉BIM数字显示建设；GIS地图及测距BIM立体显示；预留能源数据接口及其它子系统接口；支持BIM大数据驾驶舱；主要BIM的功能：信息总览，信息搭接，场景漫游，GIS定位，GIS导航，园区主要路由，园区介绍配视频及文字，夜晚及白天不同场景模式，模型重构及渲染，友好的UI界面，大数据驾驶舱；
          </td>
          <td>4周</td>
        </tr>
        <tr>
          <td>2</td>
          <td>BIMview大数据集成软件4.0</td>
          <td>BIM-Sight-20</td>
          <td>
            BIMview
            建筑物能源管理集成软件平台；20万平方米以内建筑物，场景空间视觉BIM数字显示建设；GIS地图及测距BIM立体显示；预留能源数据接口及其它子系统接口；支持BIM大数据驾驶舱；主要BIM的功能：信息总览，信息搭接，场景漫游，GIS定位，GIS导航，园区主要路由，园区介绍配视频及文字，夜晚及白天不同场景模式，模型重构及渲染，友好的UI界面，大数据驾驶舱；
          </td>
          <td>4周</td>
        </tr>
        <tr>
          <td>3</td>
          <td>BIMview大数据集成软件4.0</td>
          <td>BIM-Sight-50</td>
          <td>
            BIMview
            建筑物能源管理集成软件平台；50万平方米以内建筑物，场景空间视觉BIM数字显示建设；GIS地图及测距BIM立体显示；预留能源数据接口及其它子系统接口；支持BIM大数据驾驶舱；主要BIM的功能：信息总览，信息搭接，场景漫游，GIS定位，GIS导航，园区主要路由，园区介绍配视频及文字，夜晚及白天不同场景模式，模型重构及渲染，友好的UI界面，大数据驾驶舱；
          </td>
          <td>4周</td>
        </tr>
        <tr>
          <td>4</td>
          <td>BIMview大数据集成软件4.0</td>
          <td>BIM-Line-5</td>
          <td>
            BIMview
            建筑物能源管理集成软件平台；5万平方米以内建筑物。能源系统园区主管线BIM数字化，风系统、水系统、电系统室内主要管线及路由图纸BIM数字化，便于后期运行维护管理。增加能源设备位置信息，自产管理。增加建筑物能源数据BIM显示，能源颜色分布图；增加建筑物风、水、电等机电设备，控制设备的位置标注；支持3D展示，鼠标移动到机电及监控采集设备自动信息提醒；
          </td>
          <td>4周</td>
        </tr>
        <tr>
          <td>5</td>
          <td>BIMview大数据集成软件4.0</td>
          <td>BIM-Line-20</td>
          <td>
            BIMview
            建筑物能源管理集成软件平台；20万平方米以内建筑物。能源系统园区主管线BIM数字化，风系统、水系统、电系统室内主要管线及路由图纸BIM数字化，便于后期运行维护管理。增加能源设备位置信息，自产管理。增加建筑物能源数据BIM显示，能源颜色分布图；增加建筑物风、水、电等机电设备，控制设备的位置标注；支持3D展示，鼠标移动到机电及监控采集设备自动信息提醒；
          </td>
          <td>2周</td>
        </tr>
        <tr>
          <td>6</td>
          <td>BIMview大数据集成软件4.0</td>
          <td>BIM-Line-50</td>
          <td>
            BIMview
            建筑物能源管理集成软件平台；50万平方米以内建筑物。能源系统园区主管线BIM数字化，风系统、水系统、电系统室内主要管线及路由图纸BIM数字化，便于后期运行维护管理。增加能源设备位置信息，自产管理。增加建筑物能源数据BIM显示，能源颜色分布图；增加建筑物风、水、电等机电设备，控制设备的位置标注；支持3D展示，鼠标移动到机电及监控采集设备自动信息提醒；
          </td>
          <td>2周</td>
        </tr>
        <tr>
          <td>7</td>
          <td>BIMview大数据集成软件4.0</td>
          <td>BIM-Max-5</td>
          <td>
            BIMview
            建筑物能源管理集成软件平台；5万平方米以内建筑物。能源系统园区主管线BIM数字化，风系统、水系统、电系统室内主要管线及路由图纸BIM数字化，便于后期运行维护管理。增加能源设备位置信息，自产管理。增加建筑物能源数据BIM显示，能源颜色分布图；增加建筑物风、水、电等机电设备，控制设备的位置标注；支持3D展示，鼠标移动到机电及监控采集设备自动信息提醒；
          </td>
          <td>4周</td>
        </tr>
        <tr>
          <td>8</td>
          <td>BIMview大数据集成软件4.0</td>
          <td>BIM-Max-20</td>
          <td>
            BIMview
            建筑物能源管理集成软件平台；20万平方米以内建筑物。能源系统园区主管线BIM数字化，风系统、水系统、电系统室内主要管线及路由图纸BIM数字化，便于后期运行维护管理。增加能源设备位置信息，自产管理。增加建筑物能源数据BIM显示，能源颜色分布图；增加建筑物风、水、电等机电设备，控制设备的位置标注；支持3D展示，鼠标移动到机电及监控采集设备自动信息提醒；
          </td>
          <td>4周</td>
        </tr>
        <tr>
          <td>9</td>
          <td>BIMview大数据集成软件4.0</td>
          <td>BIM-Max-50</td>
          <td>
            BIMview
            建筑物能源管理集成软件平台；50万平方米以内建筑物。能源系统园区主管线BIM数字化，风系统、水系统、电系统室内主要管线及路由图纸BIM数字化，便于后期运行维护管理。增加能源设备位置信息，自产管理。增加建筑物能源数据BIM显示，能源颜色分布图；增加建筑物风、水、电等机电设备，控制设备的位置标注；支持3D展示，鼠标移动到机电及监控采集设备自动信息提醒；
          </td>
          <td>4周</td>
        </tr>

        <tr class="bimView_tab_title">
          <td colspan="5">BIM能源管理子系统软件接口（楼控、照明、 计量）</td>
        </tr>
        <tr>
          <td>1</td>
          <td>楼宇自控智能模块</td>
          <td>BIM-LK-V2.0</td>
          <td>
            楼宇自控系统软件接口，送排风、给排水、空调机组、新风机组、能源站等机电设备监控；支持MODBUS
            TCP，BACnet IP数据集成；
          </td>
          <td>4周</td>
        </tr>
        <tr>
          <td>2</td>
          <td>智能照明智能模块</td>
          <td>BIM-ZM-V2.0</td>
          <td>
            智能照明系统软件接口，室内照明及景观照明控制；支持MODBUS TCP，BACnet
            IP数据集成；
          </td>
          <td>4周</td>
        </tr>
        <tr>
          <td>3</td>
          <td>能源计量接口模块</td>
          <td>BIM-NY-V2.2</td>
          <td>
            智能水表、智能电表、智能冷热量表的软件接口；支持MODBUS TCP，BACnet
            IP数据集成；
          </td>
          <td>4周</td>
        </tr>
        <tr>
          <td>4</td>
          <td>冷冻机管理智能模块</td>
          <td>BIM-LD-V2.0</td>
          <td>
            制冷机组的通讯接口集成；读取制冷机组的内部数据，及通过软体控制制冷机组的启停、及出水温度设定；支持MODBUS
            TCP，BACnet IP数据集成；
          </td>
          <td>4周</td>
        </tr>
        <tr>
          <td>5</td>
          <td>变配电管理智能模块</td>
          <td>BIM-BD-V2.0</td>
          <td>
            变配电室的电力监控系统的通讯接口集成；支持MODBUS TCP，BACnet
            IP数据集成；
          </td>
          <td>4周</td>
        </tr>
        <tr>
          <td>6</td>
          <td>电梯管理智能模块</td>
          <td>BIM-DT-V2.0</td>
          <td>电梯通讯接口集成；支持MODBUS TCP，BACnet IP数据集成；</td>
          <td>2周</td>
        </tr>
        <tr>
          <td>7</td>
          <td>锅炉智能模块</td>
          <td>BIM-GL-V2.0</td>
          <td>锅炉通讯接口集成；支持MODBUS TCP，BACnet IP数据集成；</td>
          <td>4周</td>
        </tr>
        <tr>
          <td>8</td>
          <td>精密空调智能模块</td>
          <td>BIM-KT-V2.0</td>
          <td>
            机房精密空调系统通讯接口集成；支持MODBUS TCP，BACnet IP数据集成；
          </td>
          <td>4周</td>
        </tr>
        <tr>
          <td>9</td>
          <td>VAV空调智能模块</td>
          <td>BIM-VAV-V2.0</td>
          <td>
            VAV变风量空调系统通讯集成；支持MODBUS TCP，BACnet IP数据集成；
          </td>
          <td>4周</td>
        </tr>
        <tr>
          <td>10</td>
          <td>VRV空调智能模块</td>
          <td>BIM-VRV-V2.0</td>
          <td>VRV空调系统通讯接口集成；支持MODBUS TCP，BACnet IP数据集成；</td>
          <td>4周</td>
        </tr>
        <tr>
          <td>11</td>
          <td>机房监控智能模块</td>
          <td>BIM-JF-V2.0</td>
          <td>机房监控系统通讯接口集成；支持MODBUS TCP，BACnet IP数据集成；</td>
          <td>4周</td>
        </tr>
        <tr>
          <td>12</td>
          <td>发电机接口模块</td>
          <td>BIM-DJ-V2.0</td>
          <td>发电机组通讯接口集成；支持MODBUS TCP，BACnet IP数据集成；</td>
          <td>4周</td>
        </tr>
        <tr>
          <td>13</td>
          <td>网络温控器接口模块</td>
          <td>BIM-WK-V2.0</td>
          <td>
            联网风机盘管温控器系统通讯接口集成；支持MODBUS TCP，BACnet
            IP数据集成；
          </td>
          <td>4周</td>
        </tr>
        <tr>
          <td>14</td>
          <td>无线物联网系统接口模块</td>
          <td>BIM-WL-V2.0</td>
          <td>
            能源无线物联网系统，检测室内温湿度、PM2.5、一氧化碳、二氧化碳、甲醛、TVOC等空气监测；支持MODBUS
            TCP，BACnet IP数据集成；
          </td>
          <td>4周</td>
        </tr>
        <tr>
          <td>15</td>
          <td>UPS智能模块</td>
          <td>BIM-UPS-V2.0</td>
          <td>UPS电源通讯接口集成；支持MODBUS TCP，BACnet IP数据集成；</td>
          <td>4周</td>
        </tr>

        <tr class="bimView_tab_title">
          <td colspan="5">BIM安防子系统软件接口模块</td>
        </tr>
        <tr>
          <td>1</td>
          <td>闭路监控智能模块</td>
          <td>BIM-SA-V2.0</td>
          <td>闭路监控系统软件集成接口，支持厂家SDK开发包</td>
          <td>2周</td>
        </tr>
        <tr>
          <td>2</td>
          <td>报警管理智能模块</td>
          <td>BIM-BJ-V2.0</td>
          <td>报警系统软件集成接口</td>
          <td>2周</td>
        </tr>
        <tr>
          <td>3</td>
          <td>巡更管理智能模块</td>
          <td>BIM-XG-V2.0</td>
          <td>巡更系统软件集成接口</td>
          <td>2周</td>
        </tr>
        <tr>
          <td>4</td>
          <td>门禁管理智能模块</td>
          <td>BIM-MJ-V2.0</td>
          <td>门禁系统软件集成接口</td>
          <td>2周</td>
        </tr>
        <tr>
          <td>5</td>
          <td>一卡通消费智能模块</td>
          <td>BIM-ZN-V2.0</td>
          <td>一卡通系统软件集成接口</td>
          <td>2周</td>
        </tr>
        <tr>
          <td>6</td>
          <td>车场管理智能模块</td>
          <td>BIM-CC-V2.0</td>
          <td>停车场管理系统软件集成接口</td>
          <td>2周</td>
        </tr>
        <tr>
          <td>7</td>
          <td>广播通讯智能模块</td>
          <td>BIM-GB-V2.0</td>
          <td>紧急广播系统软件集成接口</td>
          <td>2周</td>
        </tr>
        <tr>
          <td>8</td>
          <td>周界报警智能模块</td>
          <td>BIM-ZJ-V2.0</td>
          <td>周界报警系统软件集成接口</td>
          <td>2周</td>
        </tr>

        <tr class="bimView_tab_title">
          <td colspan="5">BIM其它子系统软件接口模块</td>
        </tr>
        <tr>
          <td>1</td>
          <td>消防报警智能模块</td>
          <td>BIM-XF-V2.0</td>
          <td>智能消防系统及紧急疏散系统软件集成接口</td>
          <td>2周</td>
        </tr>
        <tr>
          <td>2</td>
          <td>公共显示智能模块</td>
          <td>BIM-XS-V2.0</td>
          <td>公共显示系统软件集成接口</td>
          <td>2周</td>
        </tr>
        <tr>
          <td>3</td>
          <td>信息导引及发布智能模块</td>
          <td>BIM-XXFB-V2.0</td>
          <td>信息发布系统软件集成接口</td>
          <td>2周</td>
        </tr>
        <tr>
          <td>4</td>
          <td>会议系统接口模块</td>
          <td>BIM-HY-V2.0</td>
          <td>会议系统软件集成接口</td>
          <td>2周</td>
        </tr>

        <tr class="bimView_tab_title">
          <td colspan="5">IBIMS系统集成功能扩展模块</td>
        </tr>
        <tr>
          <td>1</td>
          <td>系统联动模块</td>
          <td>BIM-LD-V2.0</td>
          <td>数据联动</td>
          <td>2周</td>
        </tr>
        <tr>
          <td>2</td>
          <td>集成发布平台</td>
          <td>BIM-WEB-V2.0</td>
          <td>WEB发布，网页浏览，支持100个用户</td>
          <td>2周</td>
        </tr>
        <tr>
          <td>3</td>
          <td>报警预案智能模块</td>
          <td>BIM-YJ-V2.0</td>
          <td></td>
          <td>2周</td>
        </tr>
        <tr>
          <td>4</td>
          <td>设备维护智能模块</td>
          <td>BIM-WH-V2.0</td>
          <td></td>
          <td>2周</td>
        </tr>
        <tr>
          <td>5</td>
          <td>邮件报警模块</td>
          <td>BIM-EM-V2.0</td>
          <td></td>
          <td>2周</td>
        </tr>
        <tr>
          <td>6</td>
          <td>能源管理模块</td>
          <td>BIM-NY-V2.0</td>
          <td>
            能源综合统计报表：日报、月报、年报，提供系统饼图、曲线图、柱状图、折线图等，历史数据查询，实时数据表。
          </td>
          <td>2周</td>
        </tr>
        <tr>
          <td>7</td>
          <td>远程维护模块</td>
          <td>BIM-WH-V2.0</td>
          <td>提供远程数据诊断及系统维护功能</td>
          <td>2周</td>
        </tr>
        <tr>
          <td>8</td>
          <td>多屏显示模块</td>
          <td>BIM-DP-V2.0</td>
          <td>此价格为单屏价格，多屏价格为单价*显示屏数量</td>
          <td>2周</td>
        </tr>
        <tr>
          <td>9</td>
          <td>大屏拼接显示控制系统智能模块</td>
          <td>BIM-DPPJ-V2.0</td>
          <td></td>
          <td>2周</td>
        </tr>

        <tr class="bimView_tab_title">
          <td colspan="5">IBIMS系统集成平台硬件设备</td>
        </tr>
        <tr>
          <td>1</td>
          <td>集成主服务器</td>
          <td></td>
          <td>联想服务器</td>
          <td>4周</td>
        </tr>
        <tr>
          <td>2</td>
          <td>集成WEB服务器</td>
          <td></td>
          <td>联想服务器</td>
          <td>4周</td>
        </tr>
        <tr>
          <td>3</td>
          <td>24口网络交换机</td>
          <td></td>
          <td>华为交换机</td>
          <td>4周</td>
        </tr>
        <tr>
          <td>4</td>
          <td>操作系统</td>
          <td></td>
          <td>微软正版软件</td>
          <td>4周</td>
        </tr>
        <tr>
          <td>5</td>
          <td>数据库</td>
          <td>SQlserver</td>
          <td>微软正版软件</td>
          <td>4周</td>
        </tr>
        <tr>
          <td>6</td>
          <td>防火墙系统</td>
          <td></td>
          <td>瑞星防毒软件</td>
          <td>4周</td>
        </tr>
      </table>
    </div>
    <!-- BIMview软件系统结束 -->
  </div>
</template>

<script>
export default {};
</script>
<style  scoped>
@import url("../css/ProjectServer.css");
</style>
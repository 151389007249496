<template>
  <div id="index">
    <!-- 公司简介开始 -->
    <div class="introduction_wrap">
      <div class="introduction_title">
        <h1>公司简介</h1>
        <p class="descri">COMPANY PROFILE</p>
      </div>
      <div class="introduction_down">
        <div class="introduction_downLeft">
          <p class="intro_txtFir">关于我们</p>
          <p class="intro_txtCon">
            北京爱博杰思信息科技有限公司（IBGMS），总部位于北京市。是以数字孪生赋能为理念，面向“新基建”，引领传统产业转型升级。通过赋能创新和管理模式创新，以用户需求为核心，以用户体验为基础，构建了在一个数字化虚拟平台实现人、物、事智能运营的先河，为广大用户提供智慧园区、建筑或分项设备系统全生命周期数字孪生赋能服务。
          </p>
          <p class="intro_txtDown">国家高新技术企业</p>
          <p class="intro_txtDown">国家双软技术企业</p>
          <p class="intro_txtDown">产业物联网引航者</p>
          <router-link :to="{ name: 'about' }">
            <div class="introduction_btn">
              <div class="introduction_btnLeft">
                <p>关于我们</p>
                <p>ABOUT US</p>
              </div>
              <i class="el-icon-right"></i>
            </div>
          </router-link>
        </div>
        <div class="introduction_downRight"></div>
      </div>
    </div>

    <!-- 公司简介结束 -->
    <!-- 核心技术开始 -->
    <div class="core">
      <div class="introduction_title">
        <h1>核心技术</h1>
        <p class="descri">CORE TECHNOLOGY</p>
      </div>
      <div class="introduction_items">
        <div class="item5">
          <span>CIM+AR+VR</span>
        </div>
        <div class="item6">
          <span>CIM人工智能与大数据技术</span>
        </div>
        <div class="item7">
          <span>CIM轻量技术</span>
        </div>
        <div class="item8">
          <span>胶水系统</span>
        </div>
        <div class="item1">
          <span>动态技术孪生</span>
        </div>
        <div class="item2">
          <span>云技术</span>
        </div>
        <div class="item3">
          <span>物联网</span>
        </div>
        <div class="item4">
          <span>大数据</span>
        </div>
      </div>
    </div>
    <!-- 核心技术结束 -->
    <!-- 项目案例开始 -->
    <div class="productCase_wrap">
      <div class="case_title">
        <h1>项目案例</h1>
        <p class="descri">PROJECT LIST</p>
      </div>

      <el-carousel height="600px" class="index_down">
        <el-carousel-item v-for="item in img_src" :key="item.id">
          <img :src="item.img" width="100%" height="100%" />
          <div class="index_downTxt">
            <P class="index_downInner1">{{ item.title }}</P>
            <P class="index_downInner2">{{ item.content }}</P>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="case_more">
        <router-link :to="{ name: 'projectCase' }" class="case_moreA">
          <span>查看更多</span>
        </router-link>
      </div>
    </div>
    <!-- 项目案例结束 -->
    <!-- 企业合作开始 -->
    <div class="index_coop">
      <div class="case_title">
        <h1>企业合作</h1>
        <p class="descri">
          ENTERPRISE COOPERATION
        </p>
      </div>
      <div class="index_coop_down">
        <div class="index_coop_item">
          <img src="../assets/首页/资源 46.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 56.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 74.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 43.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 64.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 53.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 63.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 51.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 60.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 45.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 47.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 41.png" alt="" />
        </div>

        <div class="index_coop_item">
          <img src="../assets/首页/资源 70.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 44.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 58.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 48.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 50.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 52.png" alt="" />
        </div>

        <div class="index_coop_item">
          <img src="../assets/首页/资源 54.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 55.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 57.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 59.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 61.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 65.png" alt="" />
        </div>

        <div class="index_coop_item">
          <img src="../assets/首页/资源 67.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 42.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 49.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 66.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 62.png" alt="" />
        </div>
        <div class="index_coop_item">
          <img src="../assets/首页/资源 72.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 企业合作结束 -->
  </div>
</template>
<script>
import img_1 from "../assets/项目案例/智慧轨交.png";
import img_2 from "../assets/项目案例/20、锡林郭勒超高压供电局.png";
import img_3 from "../assets/项目案例/资源 17.png";
import img_4 from "../assets/项目案例/智慧场馆.png";
export default {
  data() {
    return {
      img_src: [
        {
          id: 1,
          img: img_1,
          title: "智慧轨交",
          content:
            "智慧轨交：智慧轨交产品体系建设围绕站、车、线三个维度，以智慧场站服务、轨交车地通信、轨交信息化云服务三大核心平台建设规划为依托，打造以轨交场站为“枢纽节点”，轨交车地通信为“传输网络” ，生产管控信息化建设为“连接纽带” 的综合轨道交通信息化产品体系。"
        },
        {
          id: 2,
          img: img_2,
          title: "智慧电网",
          content:
            "智慧电网：智慧电网就是电网的智能化，它是建立在集成的、数字化孪生管理的、高速双向通信网络的基础上，通过先进的传感和测量技术、先进的设备技术、先进的控制方法以及先进的决策支持系统技术的应用，实现电网的可靠、安全、经济、高效、环境友好和使用安全的目标，其主要特征包括自愈、激励和包括用户、抵御攻击、提供用户需求的电能质量、容许各种不同发电形式的接入、启动电力市场以及资产的优化高效运行。"
        },
        {
          id: 3,
          img: img_3,
          title: "智慧园区",
          content:
            "基于CIM实现了智慧化管理的十大模块，分别为智慧能源、智慧物业、智慧楼宇、智慧消防、智慧安防、智慧停车、智慧网络、智慧环境、公共设备设施、智慧园区综合规划及3D-GIS应用等。"
        },
        {
          id: 4,
          img: img_4,
          title: "智慧场馆",
          content:
            "基于数字孪生实现了智慧化管理的八大模块，分别为智慧能源、智慧物业、智慧楼宇、智慧消防、智慧安防、智慧停车、智慧网络、智慧环境等。"
        }
      ]
    };
  }
};
</script>

<style scoped>
@import url("../css/index.css");
.index_down {
  width: 1100px;
  margin: 0 auto;
}
.index_downTxt {
  z-index: 999999;
  width: 100px;
  height: 120px;
  width: 100%;
  background-color: #000000a8;
  position: absolute;
  bottom: 0;
  left: 0;
  color: #ffffff;
  text-align: left;
  padding: 15px 35px;
  box-sizing: border-box;
}
.index_downInner1 {
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-weight: 700;
}
.index_downInner2 {
  color: #b4b4b4;
  font-size: 1rem;
  line-height: 1.15rem;
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import About from './components/about.vue'
import ProjectCase from './components/ProjectCase.vue'
import Technology from './components/Technology.vue';
import index from './components/index.vue'
import target from './components/target.vue'
import Hardware from './components/Hardware.vue'
import projectServer from './components/projectServer.vue'

import SiREAC from '../src/product/SiREAC.vue'
import SiREIBME from '../src/product/SiREIBME.vue'
import SiREIBMS from '../src/product/SiREIBMS.vue'
import SmartID from '../src/product/SmartID.vue'
Vue.use(VueRouter)
let route = new VueRouter({
    routes: [{
            path: "/",
            redirect: "/index",
        }, {
            name: "index",
            path: "/index",
            component: index,
            meta: {
                title: '首页',
                off: true
            }
        },
        {
            name: 'about',
            path: "/about",
            component: About,
            meta: {
                title: '关于我们',
                off: true

            }
        }, {
            name: 'projectCase',
            path: "/projectCase",
            component: ProjectCase,
            meta: {
                title: '项目案例',
                off: true

            }
        }, {
            name: 'target',
            path: '/target',
            component: target,
            meta: {
                title: '项目目标',
                off: true
            }
        },
        {
            name: 'projectServer',
            path: '/projectServer',
            component: projectServer,
            meta: {
                title: '产品服务',
                off: true
            }
        }, 
        {
            path: '/hardware',
            component: Hardware,
            meta: {
                title: '硬件产品',
                off: true
            },
            children: [{
                    name: 'hardware',
                    path: '',
                    redirect: "siREAC"
                },
                {
                    name: "siREAC",
                    path: 'siREAC',
                    component: SiREAC
                }, {
                    name: "smartID",
                    path: 'smartID',
                    component: SmartID

                }, {
                    name: "siREIBMS",
                    path: 'siREIBMS',
                    component: SiREIBMS
                }, {
                    name: "siREIBME",
                    path: 'siREIBME',
                    component: SiREIBME
                }
            ]
        }, {
            name: 'technology',
            path: "/technology",
            component: Technology,
            meta: {
                title: '核心技术',
                off: true
            }
        }
    ],
    linkActiveClass: "myActive"
})
route.beforeEach((to, from, next) => {
    if (to.meta.off) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        window.pageYOffset = 0;
    }
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next();
})

export default route;
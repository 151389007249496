<template>
  <div id="sireibme">
    <div class="sireibme_up">
      <p>SiREIBME 智慧建筑运维协作服务平台</p>
      <p>一个为建筑智能类系统的运行维护工作提供信息采集、沟通、分析的平台</p>
      <div class="block">
        <el-carousel indicator-position="outside" height="430px">
          <el-carousel-item v-for="item in imgList" :key="item.id">
            <img :src="item.img" width="100%" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="sireibme_down">
      <p>SiREIBME 手机APP</p>
      <span>
        业主、物业公司、运维团队可以使用手机、PC、平板等设备，通过微信、APP、浏览器访问平台。
        <br />以达到管理设备信息，安排运维工作，评估运维效果的目的，
        <br />使用SiREIBME可以让运维工作变得更简单高效。
      </span>
      <div class="sireibme_downImg">
        <img src="../assets/产品服务_硬件产品/SiREIBME04.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import sireibme01 from "../assets/产品服务_硬件产品/SiREIBME01.png";
import sireibme02 from "../assets/产品服务_硬件产品/SiREIBME02.png";
import sireibme03 from "../assets/产品服务_硬件产品/SiREIBME03.png";
export default {
  data() {
    return {
      imgList: [
        {
          id: 1,
          img: sireibme01
        },
        {
          id: 2,
          img: sireibme02
        },
        {
          id: 3,
          img: sireibme03
        }
      ]
    };
  }
};
</script>

<style>
@import url("../css/sireibme.css");
</style>
<template>
  <div>
    <div id="hardware">
      <div class="hardware_navWrap">
        <div class="hardware_title">
          <h1>硬件产品</h1>
          <p class="descri">COMPANY PROFILE</p>
        </div>
        <div class="hardware_navInner">
          <router-link :to="{ name: 'siREAC' }"
            >SiREAC
            <div class="hardware_navInner_inner">无线智能门禁系统</div>
          </router-link>
          <router-link :to="{ name: 'smartID' }"
            >SmartID
            <div class="hardware_navInner_inner">智慧园区管理平台</div>
          </router-link>
          <router-link :to="{ name: 'siREIBMS' }"
            >SiREIBMS
            <div class="hardware_navInner_inner">智慧建筑管理平台</div>
          </router-link>
          <router-link :to="{ name: 'siREIBME' }"
            >SiREIBME
            <div class="hardware_navInner_inner">智慧建筑运维协作服务平台</div>
          </router-link>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("../css/Hardware.css");
.hardware_navInner .myActive {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
  background-color: #556197 !important;
  color: rgb(255, 255, 255) !important;
  transform: translateY(2px) !important;
}
</style>
<template>
  <div id="taget">
    <div class="targetInner">
      <div class="taget_title">
        <h1>产品目标</h1>
        <p class="descri">PRODUCT OBJECTIVES</p>
      </div>
      <div class="taget_list">
        <div class="tagetList_item">
          <img src="../assets/产品服务_产品目标/轻量化.png" alt="" />
          <div class="tagetItem_txt">
            <p>轻量化</p>
            <span
              >BIM轻量化是BIM应用的重要环节，通过轻量化让BIM实现可应用化。</span
            >
          </div>
        </div>
        <div class="tagetList_item">
          <img
            class="tagetList_itemGod"
            src="../assets/产品服务_产品目标/赋值.png"
            alt=""
          />
          <div class="tagetItem_txt">
            <p>赋值</p>
            <span
              >BIM轻量化后，通过云计算技术把大数据加到BIM上，让BIM“活”起来。</span
            >
          </div>
        </div>
        <div class="tagetList_item">
          <img src="../assets/产品服务_产品目标/集成.png" alt="" />
          <div class="tagetItem_txt">
            <p>集成</p>
            <span
              >通过主流协议的数据调用，集成各种设备和定制化模块，将建筑行程统一物联网。</span
            >
          </div>
        </div>
        <div class="tagetList_item">
          <img src="../assets/产品服务_产品目标/控制.png" alt="" />
          <div class="tagetItem_txt">
            <p>控制</p>
            <span
              >设置基于云计算的综合楼宇智能控制系统，对全楼蛀牙能耗设备进行控制。</span
            >
          </div>
        </div>
        <div class="tagetList_item">
          <img
            class="tagetList_item_go"
            src="../assets/产品服务_产品目标/优化.png"
            alt=""
          />
          <div class="tagetItem_txt">
            <p>优化</p>
            <span
              >根据运行记录，定期总结运行经验，推荐优化运行策略，并对建筑运维优化。</span
            >
          </div>
        </div>
        <div class="tagetList_item">
          <img
            src="../assets/产品服务_产品目标/展示.png"
            width="150px"
            alt=""
          />
          <div class="tagetItem_txt">
            <p>展示</p>
            <span>
              将建筑的运行情况通过各种展示终端向访客发布，示范基于BIM的智慧未来建筑。</span
            >
          </div>
        </div>
      </div>
      <div class="target_first">
        <div class="target_LTitle">
          <h2>目标一：增强楼宇自动感知能力</h2>
        </div>

        <div class="target_firstDown">
          <div class="target_firRight">
            <div class="firRight_txt">
              <p>数字孪生--数字生活</p>
              <span
                >数字孪生城市把物业、交通、能源和周围生活信息相关联，打造人工智能管理中心</span
              >
            </div>
            <div class="firRight_txt">
              <p>数字孪生--数字安全管理</p>
              <span
                >数字孪生城市可以使多路摄像机画面组成一个全景监控图像，实现整体区域监控数据耦合，减少监控应急反应时间，提高安全管理效率</span
              >
            </div>
            <div class="firRight_txt">
              <p>数字孪生--系统应急指挥管理平台</p>
              <span
                >数字孪生城市打造系统应急指挥管理平台，可实现交通、反恐、海绵城市等多灾害协同应急指挥，有效保障，降低和减少人民生命和财产损失</span
              >
            </div>
            <div class="firRight_txt">
              <p>数字孪生--数字预警</p>
              <span
                >数字孪生城市实现火灾报警联动显示和应急设备控制，数据底层计算逃生路径，为受灾区域人员提供逃生参考和救援时间</span
              >
            </div>
          </div>
        </div>
      </div>

      <!--目标二开始 -->
      <div class="target_sec">
        <div class="target_LTitle">
          <h2>目标二：提升楼宇节能效果</h2>
        </div>
        <div class="target_secCenter">
          <div class="sec_cenItem">
            <div class="sec_cenItemStyle">
              <span>01</span>
            </div>
            <span>智慧园区整体大数据</span>
          </div>
          <div class="sec_cenItem">
            <div class="sec_cenItemStyle">
              <span>02</span>
            </div>
            <span>智慧楼宇信息分析</span>
          </div>
          <div class="sec_cenItem">
            <div class="sec_cenItemStyle">
              <span>03</span>
            </div>
            <span>智慧楼宇漫游运维</span>
          </div>
        </div>
        <div class="target_secDown">
          <p>
            数字孪生城市将整个区域变成统一的信息整体，从整体园区到单体楼宇，从系统设备到系统管线，包含园区建筑和设备，实现一套系统，多维管控，结合物联网与大数据分析，最终实现园区和建筑的智慧运维。
          </p>
        </div>
      </div>
      <!--目标二结束 -->

      <!-- 目标三开始 -->
      <div class="target_third">
        <div class="target_LTitle">
          <h2>目标三：物联网使智能楼宇高度集成</h2>
        </div>
        <div class="target_thirdItem">
          <div class="third_item">
            <h3>地下管廊运维</h3>
            <p>
              通过第一人称视角对整个地下管廊进行运维，可查看管线的各种参数对是否安全运行进行打分并把结果发送至管理员。
            </p>
          </div>
          <div class="third_item">
            <h3>楼宇全系统健康分析</h3>
            <p>
              通过自由视角对楼宇各系统运维，可查看系统的各种参数对系统是否安全运行、能源流向、节能等重要信息进行大数据分析，并把重要预警结果发送至管理员处理。
            </p>
          </div>
          <div class="third_item">
            <h3>光伏系统运维</h3>
            <p>
              通过鸟瞰视角对光伏系统运维，可查看光伏板的各种参数对是否安全运行或清洁进行打分并把结果发送至管理员。
            </p>
          </div>
        </div>
      </div>
      <!-- 目标三结束 -->
      <!-- 目标四开始 -->
      <div class="target_fourth">
        <div class="target_LTitle">
          <h2>目标四：大数据为客户提供便捷云服务</h2>
        </div>
        <div class="fourth_downWrap">
          <p class="fourth_downtitle">
            数字孪生城市<br />
            打造智慧生活区新体验
          </p>
          <p class="fourth_downDesc">
            手机VR在线运维模式，不仅为用户带来科技感十足的运维体验，也为将手机VR技术与物业服务结合，用户可以直接看到3D户型图、设备、管线信息等，这就为用户解决了远距离运维的问题。数字孪生城市可以实现隐蔽管线、设备和数据的增强现实的物业创新管理。
          </p>
          <div class="fourth_last">
            <div class="fourth_lastItem">
              智慧生活管理
            </div>
            <div class="fourth_lastItem">
              智慧收房管理
            </div>
            <div class="fourth_lastItem">
              消息推送管理
            </div>
            <div class="fourth_lastItem">
              智慧运维管理
            </div>
            <div class="fourth_lastItem">
              智慧选房管理
            </div>
            <div class="fourth_lastItem">
              智慧环境管理
            </div>
          </div>
        </div>
      </div>
      <!-- 目标四结束 -->
    </div>
  </div>
</template>

<script>
export default {
 
};
</script>

<style scoped>
@import url("../css/taget.css");
</style>
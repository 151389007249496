<template>
  <div id="smartID">
    <div class="smartID_title">
      <p>智慧园区管理平台整体规划</p>
      <img
        src="../assets/产品服务_硬件产品/smart01.png"
        alt="整体规划"
      />
      <span>SiREID Engine 核心架构</span>
    </div>
    <div class="smartID_intro">
      <p>产品介绍</p>
      <div class="smartID_intro_down">
        <img src="../assets/产品服务_硬件产品/smart02.png" alt="产品介绍" />
        <div class="smartID_intro_down_right">
          <p>智慧园区管理平台网络示意图</p>
          <p>
            智慧园区管理平台由园区总中心和多区域分中心组成，总中心和区域之间通过专有网络或者互联网进行链接，最终分中心数据统一进入总中心数据库，进行统一管理；分中心区域下由多种系统应用组成：消费系统、访客系统、门禁系统、停车场系统、梯控系统、考勤系统等系统组成；
            总中心可跨区域授权使用。
          </p>
        </div>
      </div>
      <div class="smartID_intro_last">
        <div class="smartID_intro_down_right">
          <p>数据引擎在虚拟融合物联网架构VF-IoT中的工作逻辑</p>
          <br />
          <p>
            根据平台的整体规划进行功能元素重组，形成全新功能化模块，根据用户的角色、场景进行逻辑定制，实现用户的交互UI使用功能以及对平台形成的大数据进行应用。
            <br />
            <br />
            即SiREID
            Engine数据引擎，完成数据存储、集群化治理及管控、兼容的接口协议、开放的定制规则，以及安全保障。
            <br />
            <br />
            实现智慧建筑中各种智能系统、软硬件产品、功能性算法的融合，融合的方式是功能及数据的接入，并且进行功能元素的抽离，为虚化层的重组做数据准备。
          </p>
        </div>
        <img src="../assets/产品服务_硬件产品/smart03.png" alt="产品介绍" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@import url("../css/smartID.css");
</style>
<template>
  <div id="app">
    <!-- 下拉显示导航条开始 -->
    <div class="slide_wrap">
      <div class="slide_inner">
        <div class="slide_left">
          <img src="../src/assets/首页/logo.png" alt="logo" />
        </div>
        <div class="slide_right">
          <router-link :to="{ name: 'index' }">首页</router-link>
          <router-link :to="{ name: 'projectCase' }">
            <span>项目案例</span>
          </router-link>
          <router-link :to="{ name: 'about' }">
            <span>关于我们</span>
          </router-link>
          <router-link :to="{ name: 'projectServer' }" class="slide_pro">
            <span>软件产品</span>
          </router-link>
          <router-link :to="{ name: 'hardware' }" class="slide_pro">
            <span>硬件产品</span>
          </router-link>
          <router-link :to="{ name: 'target' }" class="slide_pro">
            <span>产品目标</span>
          </router-link>
          <router-link :to="{ name: 'technology' }">
            <span>核心技术</span>
          </router-link>
        </div>
      </div>
    </div>
    <!-- 下拉显示导航条结束 -->

    <!-- 官网公共部分 -->
    <div class="title_wrap">
      <div id="title_topWrap">
        <div class="title_top">
          <div class="title_left">
            <img src="../src/assets/首页/logo_first.svg" alt="" />
          </div>
          <div class="title_right">
            <router-link :to="{ name: 'index' }">
              <span>首页</span></router-link
            >
            <router-link :to="{ name: 'projectCase' }">
              <span>项目案例</span>
            </router-link>
            <router-link :to="{ name: 'about' }">
              <span>关于我们</span>
            </router-link>
            <a class="title_servers_" href="#">
              <span> 产品服务 </span>
              <div class="title_servers" ref="servers">
                <router-link :to="{ name: 'projectServer' }" class="server_pro">
                  <span>软件产品</span>
                </router-link>
                <router-link :to="{ name: 'hardware' }" class="server_pro">
                  <span>硬件产品</span>
                </router-link>
                <router-link :to="{ name: 'target' }" class="server_pro">
                  <span>产品目标</span>
                </router-link>
              </div>
            </a>
            <router-link :to="{ name: 'technology' }">
              <span>核心技术</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class="title_down bodymax">
        <div id="title_text">
          <p>专注于数字孪生和物联网应用技术</p>
          <p>提供以“数字孪生”驱动的“新基建”领域与产业物联网应用综合解决方案</p>
        </div>
      </div>
      <div class="bg-video">
        <video class="v1" autoplay muted loop>
          <source src="./assets/首页/first.mp4" />
        </video>
      </div>
    </div>
    <!-- 网页公共部分结束 -->

    <!-- 路由跳转开始 -->
    <router-view></router-view>
    <!-- 路由跳转结束 -->

    <!-- 页脚开始 -->
    <div class="co">占位块元素</div>
    <footer>
      <div class="footer_inner">
        <div class="footer_up">
          <p>联系我们</p>
          <p>联系方式（同微信）：152 1049 8658</p>
          <p>地址：北京市朝阳区红军营南路傲城融富中心A座</p>
        </div>
        <div class="footer_down">
          <p>Copyright © 2021 北京爱博杰思信息科技有限公司（IBGMS）</p>
          <p>京ICP备19032424号 工信部https://beian.miit.gov.cn/</p>
        </div>
      </div>
    </footer>
    <!-- 页脚结束 -->
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  created() {
    document.addEventListener("scroll", () => {
      let slide_wrap = document.querySelector(".slide_wrap");
      if (window.scrollY > 100) {
        slide_wrap.style.opacity = "1";
        slide_wrap.style.zIndex = "555";
      } else {
        slide_wrap.style.opacity = "0";
        slide_wrap.style.zIndex = "-1";
      }
    });
  },
  watch: {
    $route(to, from) {
      let title_text = document.querySelector("#title_text");
      switch (to.path) {
        case "/about":
          title_text.innerHTML = `
             <p>打造数字孪生和物联网应用技术</p>
             <p>致力于成为全球领先的产业数字孪生平台供应商</p>
              `;
          break;
        case "/projectCase":
          title_text.innerHTML = `
             <p>智慧管理项目解决方案</p>
            `;
          break;
        case "/index":
          title_text.innerHTML = `
             <p>专注于数字孪生和物联网应用技术</p>
            <p>提供以“数字孪生”驱动的“新基建”领域与产业物联网应用综合解决方案</p>
            `;
          break;
        case "/ProductsServices":
          title_text.innerHTML = `
             <p>北京爱博杰思信息科技有限公司</p>
            <p>北京市朝阳区红军营南路傲城融富中心A座</p>
            `;
          break;
        case "/technology":
          title_text.innerHTML = `
             <p>围绕“数字孪生”自适应组态引擎</p>
            <p>基于CIM+GIS融合技术、利用云计算、物联网和大数据</p>
            `;
          break;
        case "/target":
          title_text.innerHTML = `
             <p>产品目标</p>
            <p>打造中国领先的智慧集成平台</p>
            `;
          break;
        case "/hardware":
          title_text.innerHTML = `
             <p>硬件产品</p>
            <p>公司提供基于CIM+AR+VR等相关硬件支持解决方案，
              实现人工智能在CIM领域的落地</p>
            `;
          break;
        default:
          title_text.innerHTML = `
             <p>打造数字孪生和物联网应用技术</p>
             <p>致力于成为全国领先的智能可视化公司</p>
              `;
          break;
      }
    },
  },
};
</script>

<style>
@import url("./css/reset.css");
@import url("./css/App.css");
.slide_wrap .myActive {
  background-color: rgba(131, 131, 131, 0.185) !important;
  color: rgb(15, 27, 88);
}
</style>
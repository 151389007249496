<template>
  <div id="siREIBMS">
    <div class="sireibms_up">
      <p>SiREIBMS智慧建筑管理平台</p>
      <p>智慧建筑中所有智能化子系统的大脑</p>
      <span
        >扮演着沟通者、监护者、管理者与决策者的角色。<br />
        使用者可以根据权限分配实时查看楼宇系统运行状态并获得报表，用于运营管理。</span
      >
      <img
        src="../assets/产品服务_硬件产品/SiREIBMS01.png"
        alt=""
      />
    </div>
    <div class="sireibms_down">
      <p>全程集中监视和管理</p>
      <span
        >利用通讯接口将各个子系统联接起来，通过统一的平台，实现全程集中监视和管理，
        <br />同时将数据收集存储到统一的开放式关系数据库当中，使各子系统在统一的IBMS平台进行交互及数据共享。
        <br />使用者可实时观察设备运行状态以及工况参数，实现预警、报警、联动等措施。</span
      >
      <img
        src="../assets/产品服务_硬件产品/SiREIBMS02.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@import url("../css/siREIBMS.css");
</style>
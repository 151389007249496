<template>
  <div id="aboutWrap">
    <!-- 公司资质以及专利开始 -->
    <div class="aboutFirst">
      <div class="core_title">
        <h1>公司资质及专利</h1>
        <p class="descri">QUALIFICATION AND PATENT</p>
      </div>
      <div class="aboutCenter">
        <span>专利介绍</span>
      </div>
      <div class="about_itemFir">
        <img src="../assets/版权/secon1.png" alt="" />
        <img src="../assets/版权/高新技术产业.png"  alt="" />
        <img src="../assets/版权/secon2.png"  alt="" />
      </div>
      <div class="about_itemSecond">
        <img src="../assets/版权/thir1.jpg" alt="" />
        <img src="../assets/版权/thir2.jpg" alt="" />

      </div>
      <div class="aboutDown">
        <div class="down_item"></div>
        <div class="down_item"></div>
        <div class="down_item"></div>
        <div class="down_item"></div>
        <div class="down_item"></div>
        <div class="down_item"></div>
        <div class="down_item"></div>
        <div class="down_item"></div>
        <div class="down_item"></div>
        <div class="down_item"></div>
        <div class="down_item"></div>
        <div class="down_item"></div>
      </div>
    </div>
    <!-- 公司资质以及专利结束 -->
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("../css/About.css");
</style>